import React from "react";
import Header from '../components/Header';
import Workheaderele from '../pages/Work/Workheaderele';

import { Box,  } from '@mui/material';


const Work=()=>{

    return(
        <Header>
            <Box >
                <Workheaderele/>
                
                
            </Box>
            </Header>
    )
};

export default Work;
